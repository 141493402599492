@tailwind base;
@tailwind components;
@tailwind utilities;
@import '~@fontsource/montserrat/index.css';
@import '~@fontsource/montserrat/400.css';
@import '~@fontsource/montserrat/500.css';
@import '~@fontsource/montserrat/700.css';
@import '~@fontsource/montserrat/800.css';

body {
  margin: 0;
  font-family: 'Montserrat';
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
